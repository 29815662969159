import { render, staticRenderFns } from "./field-text-variants.vue?vue&type=template&id=ba1df434&scoped=true"
import script from "./field-text-variants.vue?vue&type=script&lang=js"
export * from "./field-text-variants.vue?vue&type=script&lang=js"
import style0 from "./field-text-variants.vue?vue&type=style&index=0&id=ba1df434&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba1df434",
  null
  
)

export default component.exports